<template>
  <div class="application" v-if="isPortrait">
    <header>
      <div class="topHeader">
        <div class="logo">
          <img src="@/assets/logo.webp" alt="">
        </div>
      </div>
      <div class="bottomHeader">
        <searchBar @productSelected="handleProductSelected" @clearProdData="clearProdData"/>
      </div>
    </header>
    <div id="content" style="margin-top: ;">

      <div v-if="prodData != null">
        <div v-if="isLoading" class="loader">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <div v-else-if="error">{{ errorMessage }}</div>

        <div v-else>
          <div class="img-wrap">
            <img :src="prodData.image ? prodData.image : require('@/assets/image-model2.webp')" alt="Product Image">
          </div>
          <div class="prodName">
            <p>{{ prodData.model }}</p>
            <h2>{{ prodData.name }}</h2>
            <div class="article" >Артикул <span>{{ Article }}</span></div>
          </div>
          <div class="pricing">
            <div v-if="prodData.prp && prodData.prp != prodData.rrp">
              <p class="price">{{ prodData.prp.toLocaleString() }}<small>₸</small></p>
              <p class="sale">-{{ discount() }}%</p>
              <p class="oldPrice"><del>{{ prodData.rrp.toLocaleString() }}</del><small>₸</small></p>
            </div>
            <div v-else>
              <p class="price">{{ prodData.rrp.toLocaleString() }}<small>₸</small></p>
            </div>
            <div class="bonusWrap">
              <p class="bonus">{{ bonusCount }} Smart Bonus</p>
              <div class="bonusChanger" @click="BonusClick">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.9341 14.1577C14.6032 12.9982 15.688 11.1199 15.688 8.99999C15.688 5.47798 12.6937 2.62283 9 2.62283H8.60659M9 15.3772C5.30633 15.3772 2.31201 12.522 2.31201 8.99999C2.31201 6.88012 3.39678 5.00183 5.06589 3.84229M8.21318 16.8026L9.78682 15.3021L8.21318 13.8016M9.78682 4.19836L8.21318 2.69785L9.78682 1.19734" stroke="#029AAD" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <div class="bonusText">
                  <p class="decor-underline bonus lo" v-if="showBonusLoan">
                    Наличные
                  </p>
                  <p class="decor-underline bonus" v-else>
                    Рассрочка
                  </p>
                </div>

              </div>
            </div>
          </div>
          <div class="loanWrap" v-if="prodData.rrp > 10000">
            <p class="loan">
              {{ loanValue.value }}<small>₸</small><span> x {{ loanValue.months }} мес</span>
            </p>
            <div class="changer" @click="LoanClick" v-if="prodData.rrp > 80000">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.9341 14.1577C14.6032 12.9982 15.688 11.1199 15.688 9.00001C15.688 5.478 12.6937 2.62284 9 2.62284H8.60659M9 15.3772C5.30633 15.3772 2.31201 12.522 2.31201 9.00001C2.31201 6.88014 3.39678 5.00185 5.06589 3.84231M8.21318 16.8027L9.78682 15.3022L8.21318 13.8016M9.78682 4.19838L8.21318 2.69787L9.78682 1.19736" stroke="#2854C2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <div class="loantext">
                <p class="decor_underline twelve" v-if="showLoanFor24">
                  12 месяцев
                </p>
                <p class="decor_underline twoFour" v-else>
                  24 месяца
                </p>
              </div>
            </div>
          </div>

          <div class="specs">
            <h3>Краткие характеристики</h3>
            <div v-for="(attribute, index) in prodData.attributes" :key="index" class="spec">
              <div class="specName">{{ attribute.attribute }}</div>
              <div class="specVal">{{ attribute.parameter }}</div>
            </div>
          </div>

          <div id="quantity">
            <h3>Наличие по складам</h3>
            <div class="list-store" v-for="(city, cityIndex) in prodData.cities" :key="cityIndex">
              <div class="citi">{{ city.city }}</div>
              <div class="store" v-for="(warehouse, warehouseIndex) in city.warehouses" :key="warehouseIndex">
                <div class="location">{{ warehouse.name }}</div>
                <div class="quant" :class="getQuantityClass(warehouse.quantity)">{{ warehouse.quantity }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else></div>

    </div>
    <footer>
      <p @click="showSupportAlert">Служба поддержки</p>
    </footer>
  </div>
  <!-- <div v-else-if="isLandscape" class="landscape">
    <div class="text">
      <h3>Поверните телефон</h3>
    </div>
    <div class="image">
      <img src="/catalog/view/theme/boschcenter/app/img/rotate.webp" alt="" style="max-width: 100%;">
    </div>
  </div> -->
  <div v-else-if="isDesktop" class="desktop">
    <div class="text">
      <h3>Откройте страницу на телефоне</h3>
      <p>Данная страница не работает на компьютере, зайдите с телефона, чтобы увидеть страницу.</p>
    </div>
    <div class="image">
      <img src="/catalog/view/theme/boschcenter/app/img/desk.webp" alt="" style="max-width: 100%;">
    </div>
  </div>
  <div v-if="showAlert" class="supportAlert">
    <h3>Служба поддержки</h3>
    <p>Держитесь... Мы с Вами!</p>
    <button @click="hideSupportAlert">Принять</button>
  </div>
</template>

<script>
import axios from 'axios';
import searchBar from './components/searchBar.vue';

export default {
  name: 'App',
  components: {
    searchBar,
  },
  data() {
    return {
      prodData: null,
      isLoading: false,
      error: null,
      errorMessage: 'Произошла ошибка. Пожалуйста, попробуйте еще раз.',
      showLoanFor24: false,
      showAlert: false,
      isPortrait: false,
      isDesktop: false,
      isLandscape: false,
      Article: null,
      showBonusLoan: null,
      bonusCount: null,
    };
  },
  created() {
    this.checkOrientation();
    window.addEventListener('resize', this.checkOrientation);
  },
  unmounted() {
    window.removeEventListener('resize', this.checkOrientation);
  },

  methods: {
    handleProductSelected(productId) {
      this.fetchData(productId);
    },
    fetchData(productId) {
      this.isLoading = true;
      axios.post(`https://boschcenter.kz/index.php?route=api/product/getProduct/`, {
        product_id: productId,
      })
        .then((response) => {
          this.prodData = response.data;
          this.isLoading = false;
          this.Article = productId;
          this.calculateBonusCount();
          this.showLoanFor24 = false;
          this.showBonusLoan = false;
          
        })
        .catch((error) => {
          console.error('Ошибка при получении данных', error);
          this.error = error.message || this.errorMessage;
          this.isLoading = false;
        })
        this.calculateBonusCount();
    },
    calculateBonusCount() {
      const price = parseFloat(this.prodData.price);
      const bonus = parseFloat(this.prodData.bonus);
      this.bonusCount = Math.round((bonus / 100) * price).toLocaleString();
    },
    discount() {
      return Math.round(((this.prodData.rrp - this.prodData.prp) / this.prodData.rrp) * 100);
    },
    loan() {
      const price = parseFloat(this.prodData.price);

      let months = 1;

      if (price > 45000) {
        months = 12;
      } else if (price > 20000) {
        months = 6;
      } else if (price > 300) {
        months = 3;
      }
      return { months: months, value: Math.round(price / months).toLocaleString() };
    },
    LoanClick() {
      const price = parseFloat(this.prodData.price);
      if (price > 80000) {
        this.showLoanFor24 = !this.showLoanFor24;
      }
    },
    BonusClick() {
    const price = parseFloat(this.prodData.price);
        this.showBonusLoan = !this.showBonusLoan;
        if (this.showBonusLoan) {
          this.bonusCount = Math.round(parseFloat(this.prodData.bonus_bn / 100) * price).toLocaleString();
        } else {
          this.bonusCount = Math.round(parseFloat(this.prodData.bonus / 100) * price).toLocaleString();
        }
    },
    getQuantityClass(quantity) {
      const num = parseInt(quantity);
      if (num === 0) {
        return 'red';
      } else if (num < 5) {
        return 'yellow';
      } else {
        return '';
      }
    },
    // showSupportAlert() {
    //   this.showAlert = true;
    // },
    // hideSupportAlert() {
    //   this.showAlert = false;
    // }
    showSupportAlert() {
      window.alert('Служба поддержки\n\nДержитесь... Мы с Вами!');
    },
    checkOrientation() {
      this.isPortrait = window.matchMedia("(max-width: 1023px)").matches;
      // this.isLandscape = window.matchMedia("(max-width: 1023px) and (orientation: landscape)").matches;
      this.isDesktop = window.matchMedia("(min-width: 1024px)").matches && !this.isPortrait;
    },
    clearProdData() {
      // Очистка данных в prodData
      this.prodData = null;
    },
  },
  computed: {
    loanValue() {
      if (this.showLoanFor24) {
        return { months: 24, value: Math.round(this.prodData.price / 24).toLocaleString() };
      } else {
        return this.loan();
      }
    }
  }
}
</script>
<style>
@import "normalize.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
.prodName .article {
	font-size: 16px;
	color: #979797;
	padding-bottom: 16px;
	box-sizing: content-box;
  border-bottom: 1px solid #f0f0f0;
}
.prodName .article span {
	color: #505050;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  max-width: 100vw;
}
.desktop {
  max-width: 470px;
  margin: 80px auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.desktop .text h3 {
	font-size: 28px;
	font-weight: 700;
	margin-bottom: 16px;
}
.desktop .text p {
	font-size: 18px;
	font-weight: 400;
	margin: 0 0 40px;
	text-align: center;
}
.desktop .image img {
	max-width: 200px !important;
}
.application {
  height: calc(100vh - 184px);
  max-height: 100vh;
  max-width: 100vw;
  overflow-x: clip;
}
.landscape {
	max-width: 50%;
	margin: 50px auto;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}
.landscape .text {
	font-size: 28px;
	font-weight: 700;
	margin-right: 10px;
}
.landscape img {
	max-height: 200px;
	height: 200px;
	width: auto;
	max-width: unset !important;
}
footer {
  position: static!important;
  bottom: 0!important;
  width: 100%!important;
  padding: 20px 16px!important;
  border-top: 1px solid #f0f0f0!important;
  max-width: 100vw!important;
  background-color: #fff;
}

footer p {
  margin: 0;
  font-size: 14px;
  color: #2854c2;
}

header {
  padding: 10px 24px;
  position: relative;
  background-color: #fff;
  z-index: 2;
}

.topHeader {
  margin-bottom: 16px;
}
.changer {
	display: flex;
}
.logo img {
  max-height: 28px;
}

header {
  position: fixed!important;
  top: 0;
  left: 0;
  width: calc(100% - 48px);
}

#content {
  padding: 0 24px;
  min-height: calc(100vh - 207px);
  margin-top: 150px;
}

.searchErr {
  padding-top: 24px;
}

.img-wrap {
  margin:24px 0 16px;
}

#content .img-wrap {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

#content .img-wrap img {
  height: 212px;
  width: auto !important;
  margin: auto;
}

.prodName p {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: #505050;
}
.specs h3, #quantity h3 {
font-size: 20px;
}
.specs .specVal, #quantity .quant {
font-weight: 500 !important;
}
.prodName h2 {
  margin: 4px 0 0;
  font-size: 28px;
  font-weight: 700;
  color: #232323;
  padding: 0 0 16px;
}

.pricing {
  padding: 16px 0;
  border-bottom: 1px solid #f0f0f0;
}

.pricing .price {
  font-size: 28px;
  color: #2954c2;
  font-weight: 700;
}

.pricing p {
  margin: 0;
}

.loan {
  padding: 8px;
  width: auto;
  display: flex;
  flex-flow: row nowrap;
  background: #EBF0FF;
  color: #2954C2;
  font-size: 20px;
  font-weight: 700;
  align-items: baseline;
  margin-bottom: 0!important;
}

.loanWrap {
  width: auto;
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  gap: 12px;
}

.loan span {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-left: 8px;
  font-size: 16px;
  font-weight: 400;
}

.specs h3 {
  margin: 0 0 20px;
}

.specs {
  margin: 60px 0;
}

.spec {
  display: flex;
  flex-flow: row nowrap;
  font-size: 16px;
  font-weight: 400;
  color: #808080;
  padding: 8px 0;
  line-height: 20px;
}

.spec .specName {
  flex-basis: 50%;
}

.spec .specVal {
  color: #232323;
  flex-basis: 50%;
}

.citi {
  font-weight: 600;
  font-size: 18px;
  padding: 8px 0;
}

.store {
  display: flex;
  flex-flow: row nowrap;
  padding: 8px 0;
  font-size: 16px;
  font-weight: 400;
  gap: 20px;
}

.location {
  color: #505050;
  flex-basis: 50%;
}

.list-store+.list-store {
  margin-top: 24px;
}

.quant {
  color: #0DA300;
}

.quant.red {
  color: red;
}

.quant.yellow {
  color: #DF8D13;
}

.pricing>div:not(:empty) {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 5px 16px;
  margin-bottom: 16px;
}

#quantity {
  margin-bottom: 80px;
}

.pricing .sale {
  font-size: 20px;
  font-weight: 800;
  color: #fff;
  padding: 4px 8px;
  background: #2954c2;
}

.pricing .oldPrice {
  font-size: 20px;
  font-weight: 500;
  color: #505050;
}

.pricing .bonus {
  font-size: 16px;
  font-weight: 600;
  color: #029AAD;
}

.bonusChanger, .loanWrap .changer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 4px;

}
.loanWrap .changer p{
	color: #2854C2;
}
.bonusChanger p, .loanWrap .changer p {
		font-size: 13px !important;
		font-weight: 400!important;
		margin: 0!important;
}
.loan {
	margin: 0 !important;
}
.loanWrap {
	align-items: center !important;
	margin: 16px 0 0;
}
.bonusWrap {
	margin-bottom: 0 !important;
}
.bonusText, .loantext {
	position: relative;
}
.loantext:before {
	position:absolute;
	top: 100%;
	display:block;
	content: '';
	width: 100%;
	height: ;
	border-bottom: 2px dashed #94A4D2;
}
.bonusText:before {
	position:absolute;
	top: 100%;
	display:block;
	content: '';
	width: 100%;
	height: ;
	border-bottom: 2px dashed #87CCD4;
}



.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 250px);
  position: relative;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #f0f0f0 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}</style>
